var CUSTOMIZER_HELPER = (function (my) {
  my.configHandler = function (params) {
    const eaiConfig = APP.getProperty("project.config.explainable-ai");
    if (eaiConfig) {
      if (empty(eaiConfig.isAvailable)) {
        params.nav
          .qsa("#navGroupItem-mp-eai")
          .forEach((item) => item.addClass("hidden"));
      }
      if (empty(eaiConfig.globinterp.isAvailable)) {
        params.nav
          .qsa("#navItem-mp-eai-global-nav-link")
          .forEach((item) => item.addClass("hidden"));
      }
      if (empty(eaiConfig.localinterp.isAvailable)) {
        params.nav
          .qsa("#navItem-mp-eai-local-nav-link")
          .forEach((item) => item.addClass("hidden"));
      }
    }
  };

  my.reroute = function (params, modelType) {
    if (empty(params)) {
      params = {};
    }
    if (empty(params.subnav)) {
      if (modelType === "classification" || modelType === "anamoly") {
        params.subnav = "discrimination";
      }
      if (modelType === "regression") {
        params.subnav = "actualVsPredicted";
      }
      if (modelType === "segmentation") {
        params.subnav = "variableImportance";
      }
    }
    return params;
  };

  return my;
})(CUSTOMIZER_HELPER || {});
