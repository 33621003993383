/* global EXP_DATA, tableTemplate */
/**
 * @module EXPLORE_DATA 
 * @description This module loads and handles the explore data screen
 */
var EXPLORE_DATA = (function(my){
  /**
   * @member {string} STORE_KEY the key used to cache this screen's data in the STORE
   * @public
   */
  my.STORE_KEY="EXPLORE_DATA";

  /**
   * @method getData 
   * @description Call the server's de/exploredata API to retrieve the features data that for this connection, 
   * according to selection in modelcfg and whether it is numerical or cateogrical
   * @param {string} pkey the project key context
   * @return {object} The result object in the API result. 
   * @throws {Error} throws an error if the userHash is null or the project key is null, or any other error in API call
   * @async
   * @public
   */
  my.getData = async function(pkey){
    let summaryurl=SERVER.getBaseAddress() + 'de/exploredata',
        userHash=CREDENTIALS.getUserCreds(),
        projectKey=pkey?pkey:PROJECT.currentProjectKey();

    let storedData=STORE.getProjectData(projectKey,PROJECT.currentProjVersion(), EXPLORE_DATA.STORE_KEY);
    if (storedData){
      return storedData;
    }
    if (useTestData){
      let result=await EXP_DATA.getData();
      STORE.setProjectData(projectKey,PROJECT.currentProjVersion(), my.STORE_KEY, result);
      return result;
    }

    APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.FETCHING_FEATURES);
    if (userHash == null){
      throw new Error(i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_RETRIEVE_PROJECTS+"\n"+i18n.en.APP.UI.ERROR.EXPLORE_DATA.GENERIC);
    }
    if (projectKey == null){
      throw new Error(i18n.en.APP.UI.INFO.EXPLORE_DATA.NOT_IN_CONTEXT_OF_PROJECT);
    }

    let params={
      key: userHash, 
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
    };
    let summaryresult=null;
    try{
      summaryresult= await SERVER.postData(summaryurl, params);
    } catch (err) {
      summaryresult = null;
    }
    if(summaryresult === "ROUTES_MISMATCHED"){
      return;
    }
    if (summaryresult==null || (summaryresult.status != "success" && !(summaryresult.status >= 200 && summaryresult.status < 300))){
      APP.showError(`${i18n.en.APP.UI.ERROR.EXPLORE_DATA.GENERIC} Please contact an Administrator.`);
      APP.resetProgress();
      let err=new Error(i18n.en.APP.UI.ERROR.EXPLORE_DATA.GENERIC);err.name='GenericError';throw err;
    }
    STORE.setProjectData(pkey,PROJECT.currentProjVersion(), my.STORE_KEY, summaryresult);
    return summaryresult;
  }

  /**
   * @method tabListener 
   * @description the change listener for the hidden radio inputs that control which tab is 
   * shown on screen. Changes the "selected" class on the label that's attached to the radios.
   * @param {Event} evt the change event for the radio button selection
   * @private
   */
  var tabListener=function(evt){
    if (!evt.target.hasClass("tab-input")){return;}
    qsa(".tab-trigger[name='explore-data-tab-group']").forEach(x=>x.removeClass("selected"));
    if (evt.target.id=="categorical-input"){
      qs("#categorical-trigger").addClass("selected");
    } else if (evt.target.id=="numerical-input"){
      qs("#numerical-trigger").addClass("selected");
    }
    qs("#explore-summary-tabs-content-container input.tab-input:checked+.tab-content .explore-data-table-container").fixedTable.relayout();
  }

  /**
   * @method initListeners 
   * @description remove and re-register the tab listener.
   * @private
   */
  var initListeners=function(){
    qs("#explore-summary-tabs-content-container").removeEventListener("change", tabListener);
    qs("#explore-summary-tabs-content-container").addEventListener("change", tabListener);
  }

  /**
   * @method exploreData 
   * @description Initialize and load the page. Create the derived objects that populate the tables. Call
   * the table template (generated template from Pug) with these derived data objects as parameters. Add 
   * sorting and filtering capability using list.js
   * @param {string} pkey project key
   * @async
   * @private
   */
  my.exploreData = async function(pkey){ 
    initListeners();
    let summaryresult = await my.getData(pkey);
    let summaryTableData=[];
    let catData={
      keys: ["feature"].concat(Object.keys(summaryresult.data.posts[0].CatStatInfo)),
      colsMap: extend({feature: "Feature Name"}, summaryresult.data.posts[0].CatStatInfo),
      colsArr: ["Feature Name"].concat(Object.keys(summaryresult.data.posts[0].CatStatInfo).map(x=>summaryresult.data.posts[0].CatStatInfo[x])),
      data: Object.keys(summaryresult.data.posts[0].Categorical).map(x=>summaryresult.data.posts[0].Categorical[x]),
      format: ["s", ".2f", "s"],
    };
    let numData={
      keys: ["feature"].concat(Object.keys(summaryresult.data.posts[0].NumStatInfo)),
      colsMap: extend({feature: "Feature Name"}, summaryresult.data.posts[0].NumStatInfo),
      colsArr: ["Feature Name"].concat(Object.keys(summaryresult.data.posts[0].NumStatInfo).map(x=>summaryresult.data.posts[0].NumStatInfo[x])),
      data: Object.keys(summaryresult.data.posts[0].Numerical).map(x=>summaryresult.data.posts[0].Numerical[x]),
      format: ["s", ".2f", ".2f", ".2f", ".2f", ".2f", ".2f"],
    };
    for (let key in summaryresult.data.posts[0]){
      summaryTableData.push(summaryresult.data.posts[0][key]);
    }
    APP.resetProgress();
          
    qsa("#explore-summary-tabs-content-container .tab-content .explore-data-table-container>*").forEach((x)=>x.remove());
    qs("#explore-data-catgorical-data .explore-data-table-container").appendChild(createNode(tableTemplate({
      data: catData.data,
      headerText: catData.colsArr,
      headerKeys: catData.keys,
      hasSelectionCol:false,
      hasSelectAll: false,
      hasActionsColumn: false,
      tableAttributes: {
        "class": "fixed-table",
      },
      applyTDStyles: false,
    })));
    qs("#explore-data-numerical-data .explore-data-table-container").appendChild(createNode(tableTemplate({
      data: numData.data,
      headerText: numData.colsArr,
      headerKeys: numData.keys,
      hasSelectionCol:false,
      hasSelectAll: false,
      hasActionsColumn: false,
      tableAttributes: {
        "class": "fixed-table",
      },
      applyTDStyles: false,
    })));

    qsa("#explore-data .explore-data-table-container").forEach(x=>x.fixedTable=fixTable(x));

    my.catlist=new List(qs("#explore-data-catgorical-data"), {valueNames: catData.keys, searchClass: "input-search"});

    my.numlist=new List(qs("#explore-data-numerical-data"), {valueNames: numData.keys, searchClass: "input-search"});

    qsa("#explore-summary-tabs-content-container label.compact").forEach(label => COMPACT_LABEL.registerCompactLabel(label));
    qsa("#explore-summary-tabs-content-container button.saveAsExcel").forEach(button=>{
      let container=button.closest(".table-container");
      container.qs("table").id=container.id+"-table";
    });

  }

  return my; 

}(EXPLORE_DATA || {}));