/*global PROC_CHARTS, ACCORDION,
modeldevconfirmationdlgTemplate, modeldevelopmentTemplate, featureengineeringTemplate,
datatreatmentTemplate, MD_STATS_CHART, MD_REPORT_DATA,
summarysectionTemplate*/

/**
 * @module AE_AUTOMODEL
 * @description Behavior for the Model Development screen. The API calls it makes
 * are to the "Start Model Dev" API (/automl/v1/apis/start) and to the
 * "Report" API (/automl/v1/apis/rep). Polls the latter to keep
 * updating the status of the ModelDev process on screen.
 */
var AE_AUTOMODEL = (function (my) {
  /**
   * @member {string} varType
   * @description the variable selected in the filter box of de style tab
   * @public
   */
  my.varType = "Numerical";
  /**
   * @member {string} STORE_KEY
   * @description the key for caching data in the STORE
   * @public
   */
  my.STORE_KEY = AE_AUTOMODEL; //STORE_KEY for caching data in the STORE
  /**
   * @member {object} aeSettings
   * @description default settings flags for the UI. These keys should be
   * returned by the Start API call.
   * @private
   */
  var aeSettings = null;

  var accessType = null;

  /**
   * @member {array} fillers
   * @description These are filler paths for dials. The dial is made up of superimposed
   * SVG paths, each of which is 5% longer than the previous (lower) one.
   * @private
   */
  var fillers = [
    "<path id='filler0' value='0' d='m37.157 180.86h1' class='filler'/>",
    "<path id='filler5' value='5' d='m37.157 180.86c-5.516-5.52-10.232-11.53-14.149-17.9' class='filler'/>",
    "<path id='filler10' value='10' d='m37.157 180.86c-11.213-11.22-19.124-24.49-23.731-38.6' class='filler'/>",
    "<path id='filler15' value='15' d='m37.157 180.86c-17.04-17.05-26.452-38.83-28.236-61.11' class='filler'/>",
    "<path id='filler20' value='20' d='m37.157 180.86c-22.847-22.85-31.981-54.23-27.403-83.898' class='filler'/>",
    "<path id='filler25' value='25' d='m37.157 180.86c-28.658-28.67-35.74-70.74-21.248-106.04' class='filler'/>",
    "<path id='filler30' value='30' d='m37.157 180.86c-34.336-34.35-37.7-87.943-10.093-126.06' class='filler'/>",
    "<path id='filler35' value='35' d='m37.157 180.86c-38.065-38.077-38.065-99.813 0.000001-137.89 1.7801-1.7807 3.612-3.4781 5.4907-5.0923' class='filler'/>",
    "<path id='filler40' value='40' d='m37.157 180.86c-38.065-38.08-38.065-99.813 0-137.89 7.397-7.4 15.688-13.362 24.525-17.885' class='filler'/>",
    "<path id='filler45' value='45' d='m37.157 180.86c-38.065-38.08-38.065-99.813 0-137.89 13.185-13.19 29.208-21.81 46.103-25.862' class='filler'/>",
    "<path id='filler50' value='50' d='m37.157 180.86c-38.065-38.08-38.065-99.813 0-137.89 19.032-19.038 43.976-28.557 68.923-28.557' class='filler'/>",
    "<path id='filler55' value='55' d='m37.157 180.86c-38.065-38.08-38.065-99.813 0-137.89 24.871-24.879 59.838-33.502 91.703-25.869' class='filler'/>",
    "<path id='filler60' value='60' d='m37.157 180.86c-38.065-38.08-38.065-99.813 0-137.89 30.656-30.666 76.653-36.634 113.28-17.903' class='filler'/>",
    "<path id='filler65' value='65' d='m37.157 180.86c-38.065-38.08-38.065-99.813 0-137.89 36.282-36.295 94.053-37.993 132.34-5.095' class='filler'/>",
    "<path id='filler70' value='70' d='m37.157 180.86c-38.065-38.077-38.065-99.813 0.000001-137.89 38.064-38.076 99.776-38.076 137.84 0.000001 3.7325 3.7337 7.0991 7.6948 10.1 11.839' class='filler'/>",
    "<path id='filler75' value='75' d='m37.157 180.86c-38.065-38.077-38.065-99.813 0.000001-137.89 38.064-38.076 99.776-38.076 137.84 0.000001 9.4075 9.4103 16.49 20.266 21.248 31.852' class='filler'/>",
    "<path id='filler80' value='80' d='m37.157 180.86c-38.065-38.077-38.065-99.813 0.000001-137.89 38.064-38.076 99.776-38.076 137.84 0.000001 15.225 15.229 24.36 34.243 27.406 54.014' class='filler'/>",
    "<path id='filler85' value='85' d='m37.157 180.86c-38.065-38.077-38.065-99.813 0.000001-137.89 38.064-38.076 99.776-38.076 137.84 0.000001 21.027 21.033 30.438 49.285 28.236 76.78' class='filler'/>",
    "<path id='filler90' value='90' d='m37.157 180.86c-38.065-38.077-38.065-99.813 0.000001-137.89 38.064-38.076 99.776-38.076 137.84 0.000001 26.858 26.866 34.766 65.51 23.723 99.312' class='filler'/>",
    "<path id='filler95' value='95' d='m37.157 180.86c-38.065-38.077-38.065-99.813 0.000001-137.89 38.064-38.076 99.776-38.076 137.84 0.000001 32.55 32.56 37.266 82.419 14.149 119.99' class='filler'/>",
    "<path id='filler100' value='100' d='m37.157 180.86a97.468 97.501 0 0 1 0.000001 -137.89 97.468 97.501 0 0 1 137.84 0.000001 97.468 97.501 0 0 1 0 137.89' class='filler'/>",
  ];

  /**
   * @function getFiller
   * @description round the value parameter to the nearest 5, and return the d attribute of the corresponding path
   * @param value {number} the path to look up
   * @private
   */
  var getFiller = function (value) {
    if (value < 0) value = 0;
    if (value > 100) value = 100;
    value = Math.round(value / 5);
    // eslint-disable-next-line no-useless-escape
    return fillers[value].match(/ d=\'([\w\d\.\-\s]*)\'/)[1];
  };

  /**
   * @function prime
   * @description Prime the page for loading. Housekeeping function
   * @private
   */
  var prime = function () {
    APP.resetCurrentPageMarker();
    APP.setCurrentPageMarker("AutoModelDev");
    qs("#main-content").setAttribute("class", "");
    aeSettings = APP.getProperty("common.modelcfg.aesettings");
  };

  /**
   * @function hideBottomPage
   * @description Hides or unhides the bottom part of the page acording to its arguments.
   * @private
   */
  var hideBottomPage = function (display) {
    let elementsToHide = qsa(".hider");
    elementsToHide.forEach((element, index) => {
      elementsToHide[index].style.display = display == "YES" ? "none" : "";
    });
  };

  var populateContent = function (rowCount, aeSettings, report) {
    /*
     * The page consists of 3 areas: the completed 1 panel,
     * the completed 2 panel and the currently processing panel. Populate all
     * three with the content that goes into them.
     */
    const summaryNodes = createSummarySection(),
      summarySection = qs("dl.summary");
    Array.from(summaryNodes).forEach((node) =>
      summarySection.appendChild(node)
    );
    summarySection.setAttribute("style", `--row-count: ${rowCount}`);

    qs("#completed-panel-1").appendChild(createDataTreatmentSection());
    qs("#completed-panel-2").appendChild(createFeatureEngineeringSection());
    qsa("#completed-panel-2 h4").forEach((x) =>
      x.removeClass("running").addClass("show")
    );
    qs("#current-progress").appendChild(createDataTreatmentSection());
    qs("#current-progress").appendChild(createFeatureEngineeringSection());
    qs("#current-progress").appendChild(createModelDevSection());

    // register for expand collapse functionality for feature-engineering headers
    qsa(".fe-stats h4").forEach((y) => {
      y.addEventListener("click", function (evt) {
        if (evt.currentTarget.hasClass("show")) {
          evt.currentTarget.removeClass("show");
        } else {
          evt.currentTarget.toggleClass("current");
        }
      });
    });

    const ac = qs("#md-stats .accordion-container");
    let auc = addAccordionTab(ac, "auc-train", "AUC", "graph-icon").addClass(
      "has-chart"
    );
    auc.appendChild(createNode("<div class='chart'></div>"));
    let logloss = addAccordionTab(
      ac,
      "logloss-train",
      "LogLoss",
      "graph-icon"
    ).addClass("has-chart,last");
    logloss.appendChild(createNode("<div class='chart'></div>"));
    ACCORDION.register("#md-details .accordion-container");
    qs("#md-details .accordion-container").addEventListener(
      "panelShown",
      accordionTabListener
    );

    // update the ui based on the report received earlier from the API result.
    // if it modeldev is still running, start polling.
    my.updateProgress(report);
    if (report.State.toLowerCase() == "running") {
      qs("#model-development").addClass("started");
      updateCapabilities(aeSettings);
      qs("#model-development").addClass("started");
      APP.setProgress("Polling For Report...", false);
      let pr = my.pollReport(null, aeSettings.PollingPeriod);
      pr.then(showCompletionDialog);
      pr.catch(function (report) {
        if (
          !report ||
          !report.State ||
          report.State.toLowerCase() != "interrupted"
        ) {
          APP.router.navigate("/");
        }
      });
      APP.resetProgress();
    }
  };

  var showCpuInfo = function (aeSettings, h, w) {
    // fake processor charts with random data.
    if (aeSettings.CpuInfoAvail) {
      try {
        let procstatsAvailableCount = 0;
        for (let stat in aeSettings.procstats) {
          if (aeSettings.procstats[stat].isAvailable) {
            PROC_CHARTS.generate({
              yTicks: [0, 50, 100],
              id: stat,
              selector: `#${stat}`,
              width: w,
              height: h,
              data: Array(100).fill(0, 0), //randomData(100,40,100)
            });
            procstatsAvailableCount++;
          }
        }
        if (procstatsAvailableCount == 0) {
          qs("#processor-stats").addClass("unavailable");
        } else {
          qs("#processor-stats").setAttribute(
            "style",
            `--procstats-available-count: ${procstatsAvailableCount}`
          );
        }
      } catch (err) {
        //do nothing;
      }
    }
  };

  var handleReport = function (report) {
    if (!report || typeof report == "undefined") {
      APP.showWarning(
        "Could not retrieve report from server. Proceeding with default."
      );
      report = JSON.parse(`{
          "State": "IDLE",
          "StartTime": 0,
          "TotalExecutionTime": 0,
          "Summary": {
            "NumOfRows": "Unknown",
            "NumOfCols": "Unknown",
            "IncidenceRate": "Unknown"
          },
          "ProcessorUtilization": {
            "Enable": "TRUE",
            "Power": 0,
            "GpuTemp": 0,
            "GpuUsage": 0,
            "CpuTemp": 0,
            "CpuUsage": 0
          }
        }`);
      return false;
    }
    if (report && report.State && report.State.toLowerCase() == "completed") {
      showCompletionDialog();
      return true;
    }
  };

  /**
   * @function setupExpertExperimentation
   * @description Sets up the expert experimentation page if activated
   * @param number if expert experimentation is activated
   * @private
   */
  var setupExpertExperimentation = async function (
    expertexp,
    pkey,
    resetExpExp = false
  ) {
    // First just show the toggle button and register it's event listener
    // on toggle active - load expert experimentation
    // on toggle inactive - unload expert experimentation
    if (expertexp) {
      // Adding state change event listener on toggle button
      qs(".toggle input").addEventListener("change", function () {
        if (this.checked) {
          // Rendering model configuration screen
          hideBottomPage("YES");
          APP.pageWithoutNavBar();
          qs("#processor-stats").style.display = "none";
          qs("#unstarted .tab-container").style.display = "block";
          EXPERT_EXPERIMENTATION.load(pkey);
        } else {
          // Removing model configuration screen
          APP.pageWithBreadcrumbAndNav();
          qs("#processor-stats").style.display = "block";
          qs("#unstarted .tab-container").style.display = "none";
          hideBottomPage("NO");
        }
      });
      // Setting default display of expert experimentation UI to none.
      if (!resetExpExp) {
        qs("#unstarted .tab-container").style.display = "none";
      }
    }
  };

  /**
   * @function load
   * @description load the model dev screen. Creates dummy proc charts to load in the rhs side bar.
   * If this is called after the model dev is complete it pops up a completion dialog.
   * Else it will update the screen with the report data and start the polling cycle.
   * @param {string} pkey project key for which to load the screen
   * @private
   */
  var load = async function (pkey, loadPrime) {
    let automodel = qs("#automodel");
    const modelDevStages = APP.getProperty(
      "project.config.datapreparation",
      PROJECT.currentProjectKey()
    );
    const summaryRows = APP.getProperty(
      "project.config.modelcfg.summary.stages",
      PROJECT.currentProjectKey()
    );
    const editableSummaryRows = summaryRows.filter(
      (x) => !empty(x["multi-value"])
    );
    const nonEditableSummaryRows = summaryRows.filter((x) =>
      empty(x["multi-value"])
    );
    const rowCount = Math.max(
      editableSummaryRows.length,
      nonEditableSummaryRows.length
    );
    let expertexp = APP.getProperty(
      "project.config.modelcfg.expertexp",
      PROJECT.currentProjectKey()
    ); // Getting value of expertexp field.
    automodel.innerHTML = aeautomodelTemplate(
      modelDevStages,
      editableSummaryRows,
      aeSettings,
      rowCount,
      expertexp
    );
    automodel
      .qsa("label.compact")
      .forEach((label) => COMPACT_LABEL.registerCompactLabel(label));
    let w = parseInt(
        getComputedStyle(qs("#processor-stats")).getPropertyValue(
          "--chart-width"
        )
      ),
      h = parseInt(
        getComputedStyle(qs("#processor-stats")).getPropertyValue(
          "--chart-height"
        )
      );
    let report = await my.getReport({
      projectKey: pkey,
      projVersion: PROJECT.currentProjVersion(),
    });

    if (!loadPrime) {
      qs("#semiAuto").innerText = "Semi Auto";
    }
    APP.pageWithBreadcrumbAndNav();
    // Setup expert experimentation screen
    setupExpertExperimentation(expertexp, pkey);

    //handle report
    const isCompleted = handleReport(report);
    if (isCompleted) return;

    // show cpu info on side of the screen
    showCpuInfo(aeSettings, h, w);

    // Populate Content
    populateContent(rowCount, aeSettings, report);
  };

  /**
   * @function showCompletionDialog
   * @description Like the function says, show the completion dialog.
   * @private
   */
  var showCompletionDialog = function () {
    var dlg = qs("#model-dev-complete-dialog");
    if (!dlg || typeof dlg == "undefined") {
      dlg = createNode(modeldevconfirmationdlgTemplate());
      qs("#dialogs-sleeping").appendChild(dlg);
    }
    // eslint-disable-next-line no-unused-vars
    dlg.qsa("button.close, #next-button").forEach((x) =>
      x.addEventListener("click", (_evt) => {
        //dialog close button
        APP.hideDialog(dlg);
        dlg.remove();
        STORE.setProjectMetadata(
          PROJECT.currentProjectKey(),
          PROJECT.currentProjVersion(),
          AE_AUTOMODEL.STORE_KEY + "_modeldev_complete",
          true
        );
        APP.router.navigate("/mp/model-comparison");
      })
    );
    if (!dlg.open) APP.showDialog(dlg);
  };

  /**
   * @function createModelDevSection
   * @description Call the Pug generated template for the modelDev
   * section (with the orange and green rects). We just need this one for consistency
   * with the other two sections.
   * @private
   */
  var createModelDevSection = function () {
    const mdConfig = APP.getProperty(
      "project.config.datapreparation.modeldev.stages",
      PROJECT.currentProjectKey()
    );
    return createNode(modeldevelopmentTemplate(mdConfig));
  };

  /**
   * @function createFeatureEngineeringSection
   * @description Call the Pug generated template for
   * feature engineering (with the table of progress values and filters). If you wanted
   * to add a new row, this is where you'd need to add it for it to
   * appear in the UI. The id should match the id in the JSON data.
   * @private
   */
  var createFeatureEngineeringSection = function () {
    const feConfig = APP.getProperty(
      "project.config.datapreparation.featureengg.stages",
      PROJECT.currentProjectKey()
    );
    return createNode(featureengineeringTemplate(feConfig));
  };

  /**
   * @method createSummarySection
   * @description create the markup for the summary section in idle mode using a Pug generated
   * template function for the same. The parameter being passed to the template has key-value pairs
   * of items to include in the template
   * @private
   * @return {HTMLElement} Rendered HTML for the summary section as a string
   */
  const createSummarySection = function () {
    try {
      const summaryConfig = APP.getProperty(
        "project.config.modelcfg.summary.stages",
        PROJECT.currentProjectKey()
      ).filter((x) => empty(x["multi-value"]));
      const summaryHTML = summarysectionTemplate(summaryConfig);
      return createNode(summaryHTML);
    } catch (err) {
      return createNode("<div>No summary information found.</div>");
    }
  };

  /**
   * @function createDataTreatmentSection
   * @description Create the markup for the data treatment section
   * using a Pug generated template function for the same. The parameter being passed to the
   * template has object keys that should match the ones in the JSON data returned by the server.
   * @private
   */
  const createDataTreatmentSection = function () {
    const dtConfig = APP.getProperty(
      "project.config.datapreparation.dataengg.stages",
      PROJECT.currentProjectKey()
    );
    return createNode(datatreatmentTemplate(dtConfig));
  };

  /**
   * @member {object} MutationObserver
   * @description this observer is used to trigger ui updates on change of data attributes
   * @private
   */
  var MutationObserver =
    window.MutationObserver ||
    window.WebKitMutationObserver ||
    window.MozMutationObserver;

  /**
   * @member {MutationObserver} aeObserver
   * @description The MutationObserver allows you to keep track of changes to DOM nodes. Three types
   * of changes are trackable: attribute changes, child nodes changes and text content changes.
   * We are interested in attribute changes. This page has lots of data points to show and
   * the way it updates the whole page without updating each node by name is to use a generic
   * way to update the page. This is done by marking a dom node for watching by giving it the
   * class "bind". Either it will be a text value change (e.g. percentage value for progress update)
   * or it will be a graphical value change (for example percentage complete of a progress bar)
   * The former is generically indicated by giving it the class "contentValue" alongwith the "bind"
   * class. This will look up sub-node(s) indicated by the mutated node's "contentTarget" attribute
   * and update its text.
   * Alternatively, based on the type of progress bar, different type of graphical
   * changes are updated.
   * @private
   */
  var aeObserver = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (
        mutation.type == "attributes" &&
        mutation.attributeName.startsWith("data-")
      ) {
        if (mutation.target.hasClass("contentValue")) {
          let target = mutation.target;
          if (mutation.target.getAttribute("contentTarget")) {
            target = target.qsa(target.getAttribute("contentTarget"));
            if (!target) return;
          } else {
            target = [target];
          }
          target.forEach((x) => (x.innerText = x.getAttribute("data-value")));
        } else if (mutation.target.hasClass("fe-progress-bar")) {
          mutation.target
            .qs(".progress")
            .setAttribute(
              "style",
              `--progress-value:${parseInt(
                mutation.target.getAttribute("data-value")
              )}%`
            );
          mutation.target.qs(".value").innerText =
            mutation.target.getAttribute("data-value") + "%";
        } else if (mutation.target.id == "big-progress-dial") {
          updateBigDial();
        } else if (mutation.target.hasClass("bar")) {
          mutation.target.qs(".value").innerText =
            mutation.target.getAttribute("data-value") + "%";
          mutation.target.qs(".progress").style.width =
            parseInt(mutation.target.getAttribute("data-value")) + "%";
        } else if (mutation.target.hasClass("dial")) {
          let target = mutation.target,
            value = mutation.target.getAttribute("data-value");
          if (mutation.target.tagName.toLowerCase() == "dt") {
            target = mutation.target.nextElementSibling;
          }
          let filler = target.qs(".filler");
          let blob = target.qs(".blob");
          target.qs("span").innerText = parseInt(value) + "%";
          filler.setAttribute("d", getFiller(parseInt(value)));
          let endpt = filler.getPointAtLength(filler.getTotalLength());
          blob.setAttribute("cx", endpt.x);
          blob.setAttribute("cy", endpt.y);
        } else if (mutation.target.hasClass("md-model")) {
          let value = mutation.target.getAttribute("data-value");
          if (!value) {
            value = 0;
          }
          mutation.target.setAttribute(
            "style",
            `--progress-value: ${Math.floor(value)}%`
          );
        }
        let td = qs("#current-progress td#IV-FCDS-value");
        if (td) {
          td.closest("table").setAttribute(
            "style",
            `--td-offset-left: ${td.offsetLeft}px; --td-offset-top: ${td.offsetTop}px; --td-offset-height: ${td.offsetHeight}px;`
          );
        }
      }
    });
  });

  /**
   * @function updateBigDial
   * @description update big dial from its data attribute
   * @private
   */
  var updateBigDial = function () {
    let dial = qs("#big-progress-dial");
    let attrs = dial.attrs();
    let weights = {
      dt: parseInt(attrs["data-dt-wt"]),
      fe: parseInt(attrs["data-fe-wt"]),
      md: parseInt(attrs["data-md-wt"]),
      // mc: parseInt(attrs['data-mc-wt']),
    };
    let slices = dial.qsa(".slice");
    weights.total = weights.dt + weights.fe + weights.md;
    let dt = Math.round(
      (parseInt(attrs["data-dt-value"]) * slices.length * weights.dt) /
        weights.total /
        100
    );
    let fe = Math.round(
      (parseInt(attrs["data-fe-value"]) * slices.length * weights.fe) /
        weights.total /
        100
    );
    let md = Math.round(
      (parseInt(attrs["data-md-value"]) * slices.length * weights.md) /
        weights.total /
        100
    );
    slices.forEach((x) => x.removeClass("dt,fe,md"));
    for (let i = 0; i < slices.length; i++) {
      if (i < dt) {
        slices[i].removeClass("dt,fe,md").addClass("dt");
      }
      if (i >= dt && i < dt + fe) {
        slices[i].removeClass("dt,fe,md").addClass("fe");
      }
      if (i >= dt + fe && i < dt + fe + md) {
        slices[i].removeClass("dt,fe,md").addClass("md");
      }
    }
    if (
      parseInt(attrs["data-fe-value"]) == 100 &&
      parseInt(attrs["data-dt-value"]) == 100 &&
      parseInt(attrs["data-md-value"]) == 100
    ) {
      slices[slices.length - 1].removeClass("dt,fe,md").addClass("md");
    }
    let overall = `${Math.round(
      (parseFloat(attrs["data-dt-value"]) * weights.dt +
        parseFloat(attrs["data-fe-value"]) * weights.fe +
        parseFloat(attrs["data-md-value"]) * weights.md) /
        weights.total
    )}%`;
    dial.qs("#progress-text").innerText = overall;
    if (parseInt(overall) != 100) {
      dial.qs("#slices").addClass("animate");
    } else {
      dial.qs("#slices").removeClass("animate");
    }
  };

  /**
   * @function randomData
   * @description Generate n random data points between min and max
   * @private
   */
  var randomData = function (n, min, max) {
    var a = [];
    for (let i = 0; i < n; i++) {
      a.push(Math.floor(min + Math.random() * (max - min)));
    }
    return a;
  };

  /**
   * @method generateRandomData
   * @description Public access to the randomData function. This is only needed
   * for debugging and testing
   * @public
   */
  my.generateRandomData = randomData;

  /**
   * @method setEventListenerForStart
   * @description Sets an event listener on start button and calls a function when start is clicked
   * @param {string} pkey project key
   * @public
   */
  my.setEventListenerForStart = function (pkey) {
    qs("#start-ae").addEventListener("click", async function () {
      //event listener for the BIG start button.
      const params = {
        projectKey: pkey,
        projVersion: PROJECT.currentProjVersion(),
      };
      const summaryRows = APP.getProperty(
        "project.config.modelcfg.summary.stages",
        PROJECT.currentProjectKey()
      );
      summaryRows
        .filter((x) => !empty(x["multi-value"]))
        .forEach(
          (x) => (params[x["data-key"]] = qs(`#select-${x["data-key"]}`).value)
        );
      // eslint-disable-next-line no-unused-vars
      const result = await my.start(params);
      aeSettings.pkey = pkey;
      updateCapabilities(aeSettings);
      qs("#model-development").addClass("started");
      APP.setProgress("Polling For Report...", false);
      let pr = my.pollReport(null, aeSettings.PollingPeriod);
      pr.then(showCompletionDialog);
      pr.catch(function (report) {
        if (
          !report ||
          !report.State ||
          report.State.toLowerCase() != "interrupted"
        ) {
          APP.router.navigate("/");
        }
      });
      APP.resetProgress();
    });
  };

  /**
   * @method showAutoModel
   * @description Called from the router. Entrypoint into this module.
   * @param {string} pkey project key
   * @public
   */
  my.showAutoModel = async function (pkey, loadPrime = true) {
    if (loadPrime) {
      prime();
    }
    load(pkey, loadPrime);
    my.setEventListenerForStart(pkey);
    observe();
    accessType = readCookie("accessType");
    handleButtonDisabling();
    STORE.setProjectMetadata(
      PROJECT.currentProjectKey(),
      PROJECT.currentProjVersion(),
      AE_AUTOMODEL.STORE_KEY + "_modeldev_loaded",
      true
    );
  };

  const handleButtonDisabling = function () {
    let startProcessingBtn = qs("#start-ae");
    if (accessType == "view") {
      startProcessingBtn.disabled = true;
      startProcessingBtn.setAttribute("tooltip", "Permission Denied");
      startProcessingBtn.setAttribute("flow", "middle-top");
    }
  };

  /**
   * @function observe
   * @description Reinitialize the MutationObserver. You need this because the observer
   * stays live even when the nodes are disconnected from the DOM. In which case, if you return
   * to this page after going away, the observer will be connected to DOM nodes that aren't
   * actually visible on the page. <== Mem leak + malfunction.
   * @private
   */
  var observe = function () {
    aeObserver.disconnect();
    qsa(".bind").forEach((x) => aeObserver.observe(x, { attributes: true }));
  };

  /**
   * @function updateCapabilities
   * @description The server returns some flags for whether processor stats and
   * stop functionailty is available. set internal UI flags indicating the same.
   * @param {object} options an options object that defaults to aeSettings
   * @private
   */
  var updateCapabilities = function (options) {
    options = options ? options : aeSettings;
    if (!options.CpuInfoAvail) {
      qs("#processor-stats").addClass("unavailable");
    }
    if (!options.StopFuncAail) {
      qs("#big-stop-button").addClass("unavailable");
    }
  };

  /**
   * HOW THE POLLING CODE WORKS
   * There's a continuePolling flag that is checked each time the timer is restarted.
   * When the polling is first started, it returns a Promise that is resoved when the
   * self referential timer hits an exit condition. An exit condition is hit either when
   * the user navigates away from the page, or when the report API return completion
   * status of COMPLETED. In that case the promise is resolved.
   */

  /**
   * @member {boolean} continuePolling
   * @description If false, the polling timer will NOT be started on
   * the next iteration of the timer.
   * @private
   */
  var continuePolling = true;

  /**
   * @method cancelPolling
   * @description Sets the continuePolling flag false and kills the timer.
   * @private
   */
  var cancelPolling = function () {
    continuePolling = false;
    if (pollTimeoutToken) {
      clearTimeout(pollTimeoutToken);
    }
  };

  /**
   * @method unload
   * @description cancels polling for report and deregisters any active listeners
   * @public
   */
  my.unload = function () {
    cancelPolling();
    ACCORDION.deregister("#md-details .accordion-container");
  };

  /**
   * @member {Number} pollTimeoutToken
   * @description This is the reference that can be used to find the current timeOut that's waiting.
   * @private
   */
  var pollTimeoutToken = null;

  /**
   * @method pollReport
   * @description Start polling the report API until interrupted by user action or by completion of modelDev
   * #### HOW THE POLLING CODE WORKS
   * There's a continuePolling flag that is checked each time the timer is restarted.
   * When the polling is first started, it returns a Promise that is resoved when the
   * self referential timer hits an exit condition. An exit condition is hit either when
   * the user navigates away from the page, or when the report API return completion
   * status of COMPLETED. In that case the promise is resolved.
   * @return {Promise} Returns a promise that is resolved to a completion status. It is
   * rejected in case of any error or elapse of timeout.
   * @param {Number} timeout If the timer loop is never interrupted it should be stopped in
   * this much time in milliseconds
   * @param {Number} interval Loop every interval seconds.
   * @public
   */
  my.pollReport = function (timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 6 * 60 * 60 * 1000);
    interval = interval * 1000 || 10 * 1000;
    continuePolling = true;

    var checkCondition = async function (resolve, reject) {
      pollTimeoutToken = null;
      // If the condition is met, we're done!
      try {
        APP.setProgress("Polling For Report...", false);
        var result = await my.getReport({
          projectKey: PROJECT.currentProjectKey(),
          projVersion: PROJECT.currentProjVersion(),
        });
        APP.resetProgress();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Exception in polling.\n", err);
        APP.showError(
          "Error in retrieving updates from server. Please contact adminitrator."
        );
        return reject(err);
      }
      if (!result || typeof result == "undefined") {
        // eslint-disable-next-line no-console
        console.error("Null result in polling.");
        return reject(
          new Error(
            "Error in retrieving updates from server. Please contact adminitrator."
          )
        );
      }
      // If the condition isn't met but the timeout hasn't elapsed, go again
      else if (Number(new Date()) < endTime) {
        my.updateProgress(result);
        // if (result.ModelDev.Status.toLowerCase()=="completed" || result.State.toLowerCase() == "completed" || parseInt(result.ModelDev.CompletionPerc) == 100){
        //   resolve(result);
        // }
        try {
          if (result.ModelDev.Status.toLowerCase() == "completed") {
            return resolve(result);
          }
        } catch (err) {
          //do nothing
        }
        if (continuePolling) {
          pollTimeoutToken = setTimeout(
            checkCondition,
            interval,
            resolve,
            reject
          );
        } else {
          continuePolling = true;
          return reject({ State: "interrupted" });
        }
      }
      // Didn't match and too much time, reject!
      else {
        return reject(
          new Error("timed out for " + my.pollReport + ": " + arguments)
        );
      }
    };

    return new Promise(checkCondition);
  };

  /**
   * @method updateProgress
   * @description On receipt of data from the server (during polling) this is called with the
   * result data object to update the attributes on all the DOM nodes that are responsible for displaying
   * the associated data, either as numbers or as graphs/progress indicators. This will trigger the
   * MutationObserver that in turn updates the UI. This method is public because it sometimes needs to
   * be called from the devtools during development, adding of new values etc.
   * @param {object} data The result data from the getReport() call
   * @public
   */
  my.updateProgress = function (data) {
    observe();
    if (!PROJECT.currentProjectKey()) {
      cancelPolling();
      return;
    }
    let stageClasses = createNode("<div></div>");
    try {
      //populate dropdowns
      const summaryRows = APP.getProperty(
        "project.config.modelcfg.summary.stages",
        PROJECT.currentProjectKey()
      );
      const dtConfig = APP.getProperty(
        "project.config.datapreparation.dataengg.stages",
        PROJECT.currentProjectKey()
      );
      const feConfig = APP.getProperty(
        "project.config.datapreparation.featureengg.stages",
        PROJECT.currentProjectKey()
      );
      const mdConfig = APP.getProperty(
        "project.config.datapreparation.modeldev.stages",
        PROJECT.currentProjectKey()
      );

      // qs("#stages").removeClass("fe,md").addClass("dt");
      stageClasses.removeClass("fe,md").addClass("dt");
      // qs("#target-feature option.bind").setAttribute("data-value", data.Summary.TargetFeature);
      // qs("#data-source option.bind").setAttribute("data-value", data.Summary.DataSource);

      //populate dropdowns
      summaryRows
        .filter((row) => !empty(row["multi-value"]))
        .forEach((row) => {
          if (empty(data.Summary[row["data-key"]])) {
            data.Summary[row["data-key"]] = ["Unavailable"];
          }
          if (!Array.isArray(data.Summary[row["data-key"]])) {
            data.Summary[row["data-key"]] = [data.Summary[row["data-key"]]];
          }
          const select = qs(`#${row["data-key"]} select`);
          // select.qsa("option:not(.template)").forEach(option=>option.remove());
          // we want to preserve the selection so not removing the options and adding them
          // back. instead we are checking if an option already exists, adding it to the select
          // if it doesn't. however. stale options (ones that are not in the data) will not
          // be removed. This scenario is not expected.
          data.Summary[row["data-key"]].forEach((x, i) => {
            const optionVal = x.toLowerCase().replace(/\s/g, "_");
            if (select.qsa(`option[value='${optionVal}']`).length > 0) return; //if that option already exists in the select box return else clone the template and add it
            const clone = qs(`#${row["data-key"]} option.template`).cloneNode();
            if (i == 0) {
              clone.setAttribute("selected", "selected");
            }
            clone.removeClass("template").removeAttribute("style");
            clone.setAttribute("value", optionVal);
            clone.setAttribute("data-value", optionVal);
            clone.innerText = x;
            qs(`#${row["data-key"]} select`).appendChild(clone);
          });
        });
      const floatFormatter = d3.format("0.5f");
      for (let i = 0; i < summaryRows.length; i++) {
        if (!empty(summaryRows[i]["multi-value"])) {
          continue;
        }
        let value = parseFloat(data.Summary[summaryRows[i]["data-key"]]);
        if (Number.isNaN(value)) {
          value = data.Summary[summaryRows[i]["data-key"]];
        } else if (!Number.isInteger(value)) {
          value = floatFormatter(value);
        }
        qs(`#${summaryRows[i]["data-key"]}`).setAttribute("data-value", value);
      }
      let dvElement = qs("#dv");
      if (dvElement) {
        dvElement.setAttribute("data-value", data.Summary.dv);
      }
      //time format is 2:0:01:10.739611
      let tb = null,
        ts = "";
      if (data && data.TotalExecutionTime) {
        // eslint-disable-next-line no-useless-escape
        tb = data.TotalExecutionTime.split(":");
        const units = ["d", "h", "m", "s"];
        for (let i = tb.length - 1, j = units.length - 1; i >= 0; i--, j--) {
          ts = `${Math.round(empty(tb[i]) ? 0 : tb[i])}${units[j]} ${ts}`;
        }

        ts = ts.replace(/0[dhms]\s/, "");
        qs("#time-running").setAttribute("data-value", ts);
      }
      if (aeSettings.CpuInfoAvail) {
        try {
          //add more fake data to the fake proc charts
          for (let stat in aeSettings.procstats) {
            if (aeSettings.procstats[stat].isAvailable) {
              if (empty(data.ProcessorUtilization[stat])) {
                data.ProcessorUtilization[stat] = 0;
              }
              PROC_CHARTS.getChart(stat).flow({
                columns: [[stat].concat([data.ProcessorUtilization[stat]])],
              });
            }
          }
        } catch (err) {
          //do nothing
        }
      }

      //update data-treatment
      let dt = { total: 0, count: 0 };
      for (let i = 0; i < dtConfig.length; i++) {
        dt.total += dt[dtConfig[i]["data-key"]] =
          data.DataTreatment[dtConfig[i]["data-key"]].CompletionPerc;
        dt.count++;
      }
      dt.total = dt.total / dt.count;
      delete dt.count;

      qsa("dt.dial.dt.bind").forEach((x) =>
        x.setAttribute("data-value", Math.round(dt.total))
      ); //all the blue dials
      qs("#current-progress-dial").setAttribute(
        "data-value",
        `${Math.round(dt.total)}%`
      );
      qs("#big-progress-dial").setAttribute(
        "data-dt-value",
        `${Math.floor(dt.total)}%`
      );
      //data treatment progressbars
      for (let dtstage in dt) {
        qsa(
          `#current-progress .data-treatment .${dtstage}.bind, .completed-panel .data-treatment .${dtstage}.bind`
        ).forEach((x) => x.setAttribute("data-value", Math.round(dt[dtstage])));
      }
      if (Math.round(parseFloat("" + dt.total)) < 100) {
        qs("#stages").removeClass("fe,md,dt").addClass(stageClasses.className);
        return;
      }
      //feature engineering values

      //total completion of feature engineering
      data.FeatureEngineering.CompletionPerc = 0;
      for (let i in feConfig) {
        data.FeatureEngineering.CompletionPerc +=
          data.FeatureEngineering[feConfig[i]["data-key"]].CompletionPerc;
      }
      data.FeatureEngineering.CompletionPerc /= feConfig.length;

      //calculate running status
      if (!data.FeatureEngineering.Status) {
        data.FeatureEngineering.Status = "Running";
        let statuses = [
          ...new Set(
            feConfig.map((x) => [
              data.FeatureEngineering[x["data-key"]].Status.toLowerCase(),
              data.FeatureEngineering[x["data-key"]].Status,
            ])
          ),
        ];
        if (statuses.length === 1) {
          data.FeatureEngineering.Status = statuses[0][1];
        }
      }

      //update consolidated dial statuses everywhere at FE stage and global stage
      if (
        data.FeatureEngineering.CompletionPerc > 0 ||
        data.FeatureEngineering.Status.toLowerCase() == "running"
      ) {
        //qs("#stages").removeClass("dt,md").addClass("fe");
        stageClasses.removeClass("dt,md").addClass("fe");
        qs("#completed-panel-1").addClass("show");
      }
      qs("#current-progress-dial").setAttribute(
        "data-value",
        `${Math.round(parseFloat(data.FeatureEngineering.CompletionPerc))}%`
      );
      qs("#big-progress-dial").setAttribute(
        "data-fe-value",
        `${Math.floor(parseFloat(data.FeatureEngineering.CompletionPerc))}%`
      );
      qsa("dt.dial.fe.bind").forEach((x) =>
        x.setAttribute(
          "data-value",
          Math.round(parseFloat(data.FeatureEngineering.CompletionPerc))
        )
      ); //all the beige dials

      //update FE row statuses
      //iterate over FE stages
      for (let i in feConfig) {
        const flStage = feConfig[i]; //first-level stage: Feature Selection, Feature Extraction, Feature Optimization
        for (let j in flStage.stages) {
          const slStage = flStage.stages[j]; //second-level stage: IV, IV-FCDS etc.
          const slData =
            data.FeatureEngineering[flStage["data-key"]].SubSteps[
              slStage["data-key"]
            ];
          if (!empty(slData)) {
            if (!empty(slData.SelectedFeaturesCount))
              qsa(`.fe-stats tr.${slStage["data-key"]} .fe-value.bind`).forEach(
                (x) =>
                  x.setAttribute(
                    "data-value",
                    Math.round(parseFloat(slData.SelectedFeaturesCount))
                  )
              );
            if (!empty(slData.CompletionPerc))
              qsa(
                `.fe-stats tr.${slStage["data-key"]} .fe-progress-bar.bind`
              ).forEach((x) =>
                x.setAttribute(
                  "data-value",
                  Math.round(parseFloat(slData.CompletionPerc))
                )
              );
          }
        }
      }

      if (
        data.FeatureEngineering.Status.toLowerCase() == "running" ||
        data.FeatureEngineering.Status.toLowerCase() == "completed"
      ) {
        qs("#stages").removeClass("fe,md,dt").addClass(stageClasses.className);
        if (data.FeatureEngineering.Status.toLowerCase() == "running") {
          qs("#stages")
            .removeClass("fe,md,dt")
            .addClass(stageClasses.className);
          return;
        }
      }

      //model development values

      // qsa("dt.dial.md.bind").forEach(x=>x.setAttribute("data-value", Math.round(parseFloat((data.ModelDev.CompletionPerc)))));//all the orange dials
      // qs("#big-progress-dial").setAttribute("data-md-value", `${Math.round(parseFloat((data.ModelDev.CompletionPerc)))}%`);
      // qs("#current-progress-dial").setAttribute("data-value", `${Math.round(parseFloat((data.ModelDev.CompletionPerc)))}%`);
      // Confirmed by AMIT that it is OK to assume these models.
      const ac = qs("#md-stats .accordion-container");
      let models = mdConfig.map((m) => m["data-key"]),
        completedModelCount = 0,
        runningModelCount = 0,
        totalModelCount = 0;
      models.forEach((m) => {
        const model = qs(`#md-stats #${m}.bind`);
        let stats = data.ModelDev.ModelInfo[m];
        if (!stats) {
          //if current model has no stats go to next model
          model.addClass("unavailable");
          qs("#stages")
            .removeClass("fe,md,dt")
            .addClass(stageClasses.className);
          return;
        }
        model.setAttribute(
          "data-value",
          Math.round(parseFloat(stats.CompletionPerc))
        );
        if (stats.Status.toLowerCase() == "not started") {
          totalModelCount += parseFloat(stats.TotalModels);
        } else if (stats.Status.toLowerCase() == "done") {
          totalModelCount += parseFloat(stats.TotalModels);
          completedModelCount += parseFloat(stats.TotalModels); //parseFloat(stats.CompletedModels);
          model.removeClass("running,unavailable").addClass("ready");
        } else {
          model.removeClass("ready,unavailable").addClass("running");
          let thisTotalModels = parseFloat(stats.TotalModels);
          let thisCompletedModels = Math.round(
            (thisTotalModels * parseFloat(stats.CompletionPerc)) / 100
          ); //parseFloat(stats.CompletedModels);
          totalModelCount += thisTotalModels;
          completedModelCount += thisCompletedModels;
          runningModelCount += thisTotalModels - thisCompletedModels;
        }
        ["Auc", "LogLoss"].forEach((chartData) => {
          if (stats[`Train${chartData}Info`]) {
            if (!modelDevChartData[m]) {
              modelDevChartData[m] = {
                TrainAucInfo: [],
                TrainLogLossInfo: [],
              };
            }
            modelDevChartData[m][`Train${chartData}Info`] =
              stats[`Train${chartData}Info`];
          }
        });
      });
      let i = 0;
      let tinyboxes = qsa("#tiny-boxes .tiny-box");
      let compProp = (completedModelCount * tinyboxes.length) / totalModelCount;
      let runningProp =
        (runningModelCount * tinyboxes.length) / totalModelCount;
      let levels = [20, 50, 75];
      for (i = 0; i < tinyboxes.length; i++) {
        tinyboxes[i].removeClass("ready, running, not-started");
        if (i < Math.round(compProp)) {
          tinyboxes[i].addClass("ready");
        } else if (
          i >= Math.round(compProp) &&
          i < Math.round(compProp + runningProp)
        ) {
          tinyboxes[i]
            .addClass("running")
            .setAttribute(
              "style",
              `--fill:${levels[Math.floor(Math.random() * 3)]}%`
            );
        } else {
          tinyboxes[i].addClass("not-started");
        }
      }
      qs("#md-subtext .text.running").setAttribute(
        "data-value",
        "" + Math.round(runningProp)
      );
      qs("#md-subtext .text.total").setAttribute(
        "data-value",
        "" + Math.round(totalModelCount)
      );
      if (totalModelCount > 0) {
        data.ModelDev.CompletionPerc =
          (completedModelCount * 100) / totalModelCount;
      } else {
        data.ModelDev.CompletionPerc = 0;
      }

      qsa("dt.dial.md.bind").forEach((x) =>
        x.setAttribute(
          "data-value",
          Math.round(parseFloat(data.ModelDev.CompletionPerc))
        )
      ); //all the orange dials
      qs("#big-progress-dial").setAttribute(
        "data-md-value",
        `${Math.floor(parseFloat(data.ModelDev.CompletionPerc))}%`
      );
      if (data.ModelDev.Status.toLowerCase() != "not started") {
        qs("#current-progress-dial").setAttribute(
          "data-value",
          `${Math.round(parseFloat(data.ModelDev.CompletionPerc))}%`
        );
      }
      if (
        data.ModelDev.CompletionPerc > 0 ||
        data.ModelDev.Status.toLowerCase() == "running"
      ) {
        stageClasses.removeClass("dt,fe").addClass("md");
        //draw modeldev charts. This needs to be while the md section is visible else
        //graph area is zero and you get exceptions and misbehaviors.
        let activeChartPanel = ac.qs(".has-chart[aria-hidden='false']");
        if (activeChartPanel) {
          drawModelStatsChart(
            activeChartPanel.id.split("-")[0],
            activeChartPanel
          );
        }
        qsa(".completed-panel").forEach((x) => x.addClass("show"));
      } else {
        qs("#stages").removeClass("fe,md,dt").addClass(stageClasses.className);
        return;
      }
    } catch (err) {
      APP.showWarning("Missing information in update report.");
      qs("#stages").removeClass("fe,md,dt").addClass(stageClasses.className);
      // eslint-disable-next-line no-console
    }
    qs("#stages").removeClass("fe,md,dt").addClass(stageClasses.className);
  };

  /**
   * @method addAccordionTab
   * @description Create a new accordion tab from the template elements in the accordionContainer and append it.
   * @param {Element} accodionContainer the outer container for the accordion. This element will be searched
   * for the .template elements to clone, modify and add back
   * @param {string} idSlug This string is converted to lowercase and prepended to other strings to create various attribute
   * values for the tab, content and radio button used in the markup for the new tab
   * @param {string} title The display name of the tab
   * @param {string} iconClass The class added to the label for displaying an icon on the accordion tab.
   * @return {HTMLDivElement} The panel created as the new element
   * @private
   */
  var addAccordionTab = function (
    accordionContainer,
    idSlug,
    title,
    iconClass
  ) {
    let label = accordionContainer.qs("label.template").cloneNode(),
      radio = accordionContainer.qs("input.template").cloneNode(),
      panel = accordionContainer.qs("div.template").cloneNode();
    [label, radio, panel].forEach((x) => x.removeClass("template"));
    label.addClass(iconClass);
    label.innerText = title;
    label.id = idSlug + "-tab";
    radio.id = idSlug + "-radio";
    panel.id = idSlug;
    [label, radio].forEach((x) => x.setAttribute("aria-controls", idSlug));
    if (accordionContainer.qsa(".accordion-tab:not(.template)").length == 0) {
      //if this is the first non-template tab set it visible
      label.setAttribute("aria-selected", true.toString());
      panel.setAttribute("aria-hidden", false.toString());
    }
    label.setAttribute("for", radio.id);
    panel.setAttribute("aria-labelledby", label.id);
    [label, radio, panel].forEach((x) => accordionContainer.appendChild(x));
    return panel;
  };

  /**
   * @member modelDevChartData
   * @description temporary storage for chart data until it is needed for rendering the chart.
   * This is received in the report fetched from the API call to the server.
   * @property {Array} modelDevChartData.auc_train The AUC train data
   * @property {Array} modelDevChartData.logloss The LogLoss train data
   */
  var modelDevChartData = {};

  /**
   * @method accordionTabListener
   * @description listen for changes to the accordion tab and render the graph if needed
   * @param {Event | HTMLDivElement} evtOrPanel
   */
  var accordionTabListener = function (evtOrPanel) {
    let panel = evtOrPanel;
    if (evtOrPanel instanceof Event) {
      panel = evtOrPanel.detail.newPanel;
    }
    if (panel.hasClass("has-chart")) {
      drawModelStatsChart(panel.id.split("-")[0], panel);
    }
  };

  /**
   * @method drawModelStatsChart
   * Draw the AUC/LogLogloss chart in specified panel using C3.js
   * @param {string} chartName auc/logloss
   * @param {HTMLElement} panel Panel into which the chart should be drawn
   * @returns undefined
   * @private
   */
  var drawModelStatsChart = function (chartName, panel) {
    let dataName = {
      auc: "TrainAucInfo",
      logloss: "TrainLogLossInfo",
    }[chartName];
    let w = panel.offsetWidth,
      h = panel.offsetHeight;
    const mdConfig = APP.getProperty(
      "project.config.datapreparation.modeldev.stages",
      PROJECT.currentProjectKey()
    );
    let models = mdConfig.map((m) => m["data-key"]),
      chartData = [];
    for (let i in models) {
      let model = models[i];
      if (modelDevChartData[model]) {
        chartData.push([model].concat(modelDevChartData[model][dataName]));
      }
    }

    MD_STATS_CHART.generate({
      // yTicks: [0,1],
      id: chartName,
      selector: `#${panel.id} .chart`,
      width: w - 31,
      height: h,
      data: chartData,
    });
  };

  /**
   * @method getReport
   * @description Make a fetch() call to the API server for fetching the report.
   * This function will wait until a result is returned. This is public only for development
   * purposes, to enable being called from devtools
   * @return {object} result object of the report API
   * @param {object} iparams contains the userHash as "key" and project key as "projectKey".
   * @public
   */
  my.getReport = async function (iparams) {
    if (useTestData) {
      let rep = await MD_REPORT_DATA.getReport();
      return rep;
    }
    return API_HELPER.getResult("rep", iparams);
  };

  /**
   * @method start
   * @description Start the modelDev process by making a fetch() call to the API server.
   * This function will wait until a response from the server.
   * @return {object} A capabilities object on success.
   * @param {object} iparams contains the userHash as "key" and project key as "projectKey".
   * @public
   */
  my.start = async function (iparams) {
    const url = SERVER.getBaseAddress() + "start";
    const userHash = CREDENTIALS.getUserCreds();
    APP.setProgress("Starting project...");
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    const params = extend(
      {
        key: userHash,
        projectKey: "",
        projVersion: "",
      },
      iparams
    );

    let result = null;
    try {
      if (useTestData) {
        result = await MD_REPORT_DATA.start(params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    APP.resetProgress();
    // eslint-disable-next-line no-console
  };

  return my;
})(AE_AUTOMODEL || {});
